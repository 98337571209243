import styled, { css } from 'styled-components'
import { breakpoints } from '@/styles/global'

interface Props {
  open: boolean
}

type LiProps = {
  active: boolean
}

export const Container = styled.div<Props>`
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--gray);
  position: fixed;
  top: 0;
  right: 0;
  height: 100vh;
  width: 250px;
  transition: 0.4s transform;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
  display: none;

  @media (max-width: ${breakpoints.maxWidth}) {
    display: block;
  }

  ${({ open }) =>
    !open &&
    css`
      display: none;
    `};
`

export const Ul = styled.ul`
  box-shadow: -9px -15px 22px -14px var(--black);
  list-style: none;
  display: flex;
  flex-direction: column;
  column-gap: 40px;
  padding: 50px 40px 0;
`

const liActive = css`
  color: var(--yellow);
  font-weight: 700;
`

export const Li = styled.li<LiProps>`
  list-style: unset;
  padding: 18px 10px;
  text-decoration: none;
  color: var(--white);
  font-size: 20px;
  cursor: pointer;
  text-transform: uppercase;
  transition: color 0.5s;

  ${({ active }) => active && liActive}

  &:hover {
    ${liActive}
  }

  & + a {
    margin-top: 10px;
  }
`
