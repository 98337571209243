import styled from 'styled-components'
import { breakpoints } from '@/styles/global'

export const Container = styled.div`
  max-width: ${breakpoints.maxWidth};
  margin: 0 auto;
  padding: 40px;

  > h2 {
    font-weight: 300;
    text-align: center;
    padding: 64px 0;

    span {
      font-weight: 900;
    }
  }
`

export const ContentContainer = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 50px;

  @media (max-width: ${breakpoints.maxWidth}) {
    flex-wrap: wrap;
  }
`

export const Image = styled.div<{ image?: string }>`
  min-width: 400px;
  min-height: 500px;
  background-image: url(${({ image }) => image && image});
  background-color: var(--white);
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: ${breakpoints.maxWidth}) {
    width: 100%;
  }
`

export const Description = styled.div`
  p {
    text-align: justify;
    line-height: 24px;
    color: var(--white);

    & + p {
      margin-top: 32px;
    }
  }

  div {
    margin-top: 32px;
    display: flex;
    column-gap: 20px;

    a {
      text-decoration: none;
      border: 0;
      padding: 12px 24px;
      cursor: pointer;
      border-radius: 8px;
      background-color: var(--white);
      color: var(--black);

      transition: 0.4s filter;

      &:hover {
        filter: brightness(0.5);
      }
    }
  }

  @media (max-width: ${breakpoints.maxWidth}) {
    margin-top: 32px;
  }
`

export const LinksContainer = styled.div`
  a {
    white-space: nowrap;
  }

  @media (max-width: ${breakpoints.maxWidth}) {
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
  }
`
