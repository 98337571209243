import { Container, Image, Message, Text } from './styles'

type CardProps = {
  text: string
  author: string
}

export function Card({ author, text }: CardProps) {
  return (
    <Container>
      <Image />
      <Message>
        <Text>
          "{text}"
          <br />—<span>{author}</span>
        </Text>
      </Message>
    </Container>
  )
}
