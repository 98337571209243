import { BsCalendarFill } from 'react-icons/bs'

import { Container, Date, Separator, Title, Description, Place } from './styles'

export type CardProps = {
  start?: {
    month?: string
    year?: string
  }
  end?: {
    month?: string
    year?: string
  }
  title: string
  description: string
  place: string
}

export function Card({ start, end, title, description, place }: CardProps) {
  return (
    <Container>
      <Date>
        <BsCalendarFill />
        <span>{start?.month + ', ' + start?.year}</span>
        <Separator />
        <span>
          {end?.month}
          {end?.year && ', ' + end?.year}
        </span>
      </Date>
      <Title>{title}</Title>
      <Place>{place}</Place>
      <Description>{description}</Description>
    </Container>
  )
}
