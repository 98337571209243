import { TitleStyled } from './styles'

type TitleProps = {
  additional: string
  title: string
}

export function Title({ additional, title }: TitleProps) {
  return (
    <>
      <TitleStyled>
        {additional}
        <span>{title}</span>
      </TitleStyled>
    </>
  )
}
