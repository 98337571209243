import { Footer } from '@/components'
import { useMenu } from '@/hooks'

import { Container, Ul, Li } from './styles'

export interface PropsNav {
  open: boolean
  setOpen: () => void
}

export function RightNav() {
  const { menuList, isMenuOpened, onClickMenuItem } = useMenu()

  return (
    <Container open={isMenuOpened}>
      <Ul>
        {menuList.map(({ label, active, id }) => (
          <Li key={id} onClick={() => onClickMenuItem(id)} active={active}>
            {label}
          </Li>
        ))}
      </Ul>
      <Footer />
    </Container>
  )
}
