import styled from 'styled-components'
import { breakpoints } from '@/styles/global'

export const Container = styled.div`
  max-width: ${breakpoints.maxWidth};
  margin: 0 auto;
  padding: 40px 40px 200px;

  display: flex;
  flex-direction: column;
`

export const ContentContainer = styled.div`
  display: flex;
  column-gap: 48px;

  @media (max-width: ${breakpoints.maxWidth}) {
    flex-wrap: wrap;
    flex-direction: column;
  }
`

export const Section = styled.div`
  h2 {
    text-align: left;
    padding-bottom: 8px;
  }
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  flex: 1;

  @media (max-width: ${breakpoints.maxWidth}) {
    & + div {
      margin-top: 40px;
    }
  }
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Label = styled.label`
  color: var(--yellow);
  margin-bottom: 8px;
  text-transform: uppercase;
`

export const Info = styled.span`
  color: var(--white);
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`

export const Input = styled.input`
  margin-bottom: 12px;
  background-color: var(--gray-300);
  border: 0;
  height: 40px;
  border-radius: 4px;
  color: var(--white);
  padding: 16px;
  font-size: 16px;

  &::placeholder {
    font-size: 16px;
    color: var(--gray-900);
    text-transform: uppercase;
  }
`

export const Message = styled.textarea`
  margin-bottom: 12px;
  background-color: var(--gray-300);
  border: 0;
  resize: none;

  border-radius: 4px;
  color: var(--white);
  padding: 16px;
  font-size: 16px;

  &::placeholder {
    font-size: 16px;
    color: var(--gray-900);
    text-transform: uppercase;
  }
`

export const Button = styled.button`
  border: 0;
  padding: 12px 40px;
  cursor: pointer;
  border-radius: 4px;
  width: fit-content;

  transition: 0.4s filter;

  &:hover {
    filter: brightness(0.5);
  }
`

export const IconsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Icon = styled.a<{ tooltip: string }>`
  text-decoration: none;
  padding: 12px;
  border: 1px solid var(--white);
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  transition: all 0.4s;

  margin-right: 16px;
  margin-bottom: 16px;

  svg {
    font-size: 24px;
    color: var(--white);
    transition: color 0.4s;
  }

  img {
    height: 24px;
    width: 24px;
    color: var(--yellow);
    transition: color 0.4s;
  }

  position: relative;

  &:hover {
    border: 1px solid var(--yellow);

    svg,
    img {
      color: var(--yellow);
    }

    svg path {
      fill: var(--yellow);
    }
  }

  &:hover::before {
    position: absolute;
    top: -20px;
    background-color: var(--white);
    color: var(--gray);
    content: ${({ tooltip }) => tooltip};
  }
`
