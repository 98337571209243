import { profileImage } from '@/assets'
import { Title } from '@/components'

import { Container, ContentContainer, Image, Description, LinksContainer } from './styles'

export function About() {
  return (
    <Container id="about-section">
      <Title additional="My" title="About" />

      <ContentContainer>
        <Image image={profileImage} />
        <Description>
          <p>
            I'm passionate about what I do, 100% engaged. Focused as a frontend, but with horizons
            for fullstack with JavaScript (Node.JS, React and React Native). Lately I have applied
            knowledge in prototyping and developing dashboards with the most current technologies on
            the market and tools like Figma, providing better usability for its users and assertive
            decision making.
          </p>

          <p>
            I am looking to grow in my career as a Software developer and develop seniority in
            Javascript stacks (React and Angular2+) and be able to add in projects that benefit the
            growth of the organization and my professional growth. I have experience in face-to-face
            work and am looking for a 100% remote opportunity.
          </p>

          <p>
            My current job allows me to exercise knowledge on the frontend as well as the backend
            and goes far beyond that. In it I have experienced how management works, negotiations
            with clients, decision making... For sure something that has added me and it has been a
            challenging experience, but very exciting
          </p>

          <LinksContainer>
            <a href="/#contact">Hire me!</a>
            <a href="/curriculum-en.pdf" target="_blank">
              Download CV (En)
            </a>
            <a href="/curriculum-pt-br.pdf" target="_blank">
              Download CV (Pt-br)
            </a>
          </LinksContainer>
        </Description>
      </ContentContainer>
    </Container>
  )
}
