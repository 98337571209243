import styled from 'styled-components'
import { breakpoints } from '@/styles/global'

export const Container = styled.div`
  display: flex;
  column-gap: 20px;
  row-gap: 20px;
  align-items: flex-start;

  & + div {
    margin-top: 20px;
  }
`

export const Image = styled.div<{ image?: string }>`
  border-radius: 50%;
  min-height: 40px;
  min-width: 40px;
  background-color: var(--dark);

  @media (max-width: ${breakpoints.maxWidth}) {
    display: none;
  }
`

export const Message = styled.div`
  padding: 40px;
  background-color: var(--gray-300);
  border-radius: 8px;
  position: relative;

  display: flex;
  flex: 1;
  overflow: visible;

  &::before {
    content: '';
    position: absolute;
    background-color: var(--gray-300);

    height: 15px;
    width: 15px;
    top: 5px;
    left: -5px;
    transform: rotateZ(45deg);
  }
`

export const Text = styled.p`
  line-height: 24px;
  font-style: italic;

  span {
    color: var(--yellow);
    margin-left: 10px;
    font-style: normal;
  }

  & + p {
    margin-top: 20px;
  }
`
