import { MenuMobile, MenuDesktop } from './components'
import { useWindow } from '@/hooks'

import { Container } from './styles'

export function Menu() {
  const { isMobile } = useWindow()

  if (isMobile) {
    return (
      <Container>
        <MenuMobile />
      </Container>
    )
  }

  return (
    <Container>
      <MenuDesktop />
    </Container>
  )
}
