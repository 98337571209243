import { SiGithub } from 'react-icons/si'
import { FiEye } from 'react-icons/fi'

import { Container, Content, ButtonContainer, Image } from './styles'

type CardProps = {
  image: string
  title: string
  description: string
  link: {
    github: string
    demo: string
  }
}

export function Card({ image, title, description, link }: CardProps) {
  return (
    <Container>
      <Image image={image} />

      <Content>
        <h3>{title}</h3>
        <p>{description}</p>

        <ButtonContainer>
          <a href={link.github} target="_blank" rel="noreferrer">
            <SiGithub />
            GitHub
          </a>
          <a href={link.demo} target="_blank" rel="noreferrer">
            <FiEye />
            Demo
          </a>
        </ButtonContainer>
      </Content>
    </Container>
  )
}
