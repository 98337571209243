import { useMemo } from 'react'
import { Title } from '@/components'
import { Card } from './Card'

import { Container, ContentContainer, CardContainer } from './styles'

export function Resume() {
  const cardsEducation = useMemo(
    () => [
      {
        start: {
          month: 'April',
          year: '2019'
        },
        end: {
          month: 'November',
          year: '2019'
        },
        title: 'QA Trainee',
        description:
          'Automated tests development, unit tests executions using Python, Django, and others. Viability study about Jest or Jenkins application. Unit tests in local enviroment, test plan on homologation enviroment too.',
        place: 'Brisanet Telecomunicações'
      },
      {
        start: {
          month: 'August',
          year: '2017'
        },
        end: {
          month: 'December',
          year: '2019'
        },
        title: 'Analysis and Systems Development',
        description: 'Degree concluded',
        place: 'Universidade Paraíso do Ceará'
      },
      {
        start: {
          month: 'January',
          year: '2015'
        },
        end: {
          month: 'December',
          year: '2016'
        },
        title: 'Radiology Technician',
        description: 'Degree concluded',
        place: 'Faculdade de Medicina de Juazeiro do Norte'
      },
      {
        start: {
          month: 'August',
          year: '2011'
        },
        end: {
          month: 'December',
          year: '2013'
        },
        title: 'Surveying Techinician',
        description: 'Degree concluded',
        place: 'EEEP. Raimundo Saraiva Coelho'
      },
      {
        start: {
          month: 'August',
          year: '2011'
        },
        end: {
          month: 'December',
          year: '2013'
        },
        title: 'High School',
        description: 'Degree concluded',
        place: 'EEEP. Raimundo Saraiva Coelho'
      }
    ],
    []
  )

  const cardsExperience = useMemo(
    () => [
      {
        start: {
          month: 'November',
          year: '2021'
        },
        end: {
          month: 'Currently'
        },
        title: 'Frontend Developer',
        description:
          'Systems development in a financial branch using ReactJS and NextJS technologies mainly',
        place: 'CI&T'
      },
      {
        start: {
          month: 'April',
          year: '2021'
        },
        end: {
          month: 'November',
          year: '2021'
        },
        title: 'Frontend Developer',
        description:
          'Systems development in a financial branch using ReactJS and NextJS technologies mainly',
        place: 'Dextra'
      },
      {
        start: {
          month: 'February',
          year: '2020'
        },
        end: {
          month: 'March',
          year: '2021'
        },
        title: 'Frontend Developer',
        description:
          'Frontend act in intern system with title "Cabeamento", responsible to manager daily activities and its development. In this project I used Angular2+.',
        place: 'Brisanet Telecomunicações'
      },
      {
        start: {
          month: 'November',
          year: '2019'
        },
        end: {
          month: 'February',
          year: '2020'
        },
        title: 'QA',
        description:
          'Act with Tester, responsible for ensure quality and deliver intern softwares.',
        place: 'Brisanet Telecomunicações'
      },
      {
        start: {
          month: 'April',
          year: '2019'
        },
        end: {
          month: 'November',
          year: '2019'
        },
        title: 'QA Trainee',
        description:
          'Automated tests development, unit tests executions using Python, Django, and others. Viability study about Jest or Jenkins application. Unit tests in local environment, test plan on homologate environment too.',
        place: 'Brisanet Telecomunicações'
      }
    ],
    []
  )

  return (
    <Container id="resume-section">
      <Title additional="My" title="Resume" />

      <ContentContainer>
        <CardContainer>
          <h2>Education</h2>
          {cardsEducation.map((card, index) => (
            <Card key={index} {...card} />
          ))}
        </CardContainer>
        <CardContainer>
          <h2>Experience</h2>
          {cardsExperience.map((card, index) => (
            <Card key={index} {...card} />
          ))}
        </CardContainer>
      </ContentContainer>
    </Container>
  )
}
