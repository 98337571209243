import React, { useMemo, useCallback } from 'react'
import { SiGithub, SiGmail, SiLinkedin } from 'react-icons/si'
import { Title } from '@/components'

import {
  Container,
  ContentContainer,
  Section,
  Form,
  Input,
  Message,
  Button,
  InfoContainer,
  Label,
  Info,
  IconsContainer,
  Icon
} from './styles'

export function Contact() {
  const info = useMemo(
    () => [
      { label: 'email', value: 'moesiomarcelino1@gmail.com' },
      { label: 'city', value: 'Juazeiro do Norte - CE' },
      { label: 'country', value: 'Brazil' }
    ],
    []
  )

  const icons = useMemo(
    () => [
      {
        icon: () => <SiGmail />,
        route: 'mailto:moesiomarcelino1@gmail.com',
        tooltip: 'Gmail'
      },
      {
        icon: () => <SiLinkedin />,
        route: 'https://linkedin.com/in/moesiomarcelino/',
        tooltip: 'Linkedin'
      },
      {
        icon: () => <SiGithub />,
        route: 'https://github.com/moesiomarcelino/',
        tooltip: 'GitHub'
      }
    ],
    []
  )

  const handleSubmit = useCallback(e => {
    e.preventDefault()

    const {
      name,
      email: emailForm,
      phone,
      message
    } = {
      name: e.target[0].value,
      email: e.target[1].value,
      phone: e.target[2].value,
      message: e.target[3].value
    }

    const email = 'moesiomarcelino1@gmail.com'

    window.open(`
      mailto:
        ${email}
        ?subject=Olá Moésio, estou entrando em contato para...
        &cc=${emailForm}
        &body=Olá Moésio, me chamo ${name} (${phone} - ${emailForm}) e estou entrando em contato para:
        ${message}
    `)
  }, [])

  return (
    <Container id="contact-section">
      <Title additional="Get In" title="Touch" />

      <ContentContainer>
        <Section>
          <Title additional="Get In Touch" title="" />
          <Form onSubmit={handleSubmit}>
            <Input type="text" required name="name" id="name" placeholder="your name" />
            <Input type="email" required name="email" id="email" placeholder="your email" />
            <Input type="tel" required name="phone" id="phone" placeholder="your phone" />
            <Message
              required
              name="message"
              id="message"
              cols={30}
              rows={5}
              placeholder="your message"
            />
            <Button type="submit">Send message</Button>
          </Form>
        </Section>

        <Section>
          <Title additional="My Contact Details" title="" />
          {info.map(({ label, value }, index) => (
            <InfoContainer key={index}>
              <Label>{label}</Label>
              <Info>{value}</Info>
            </InfoContainer>
          ))}

          <IconsContainer>
            {icons.map(({ icon: IconReact, route, tooltip }, index) => (
              <Icon key={index} href={route} tooltip={tooltip} target="_blank">
                <IconReact />
              </Icon>
            ))}
          </IconsContainer>
        </Section>
      </ContentContainer>
    </Container>
  )
}
