import { RightNav } from '..'
import { useMenu } from '@/hooks'

import { Container, StyledBurger } from './styles'

export function MenuMobile() {
  const { isMenuOpened, toggleIsMenuOpened } = useMenu()

  return (
    <Container>
      <img src="./favicon.svg" alt="Logo portfolio" />
      <StyledBurger open={isMenuOpened} onClick={toggleIsMenuOpened}>
        <div />
        <div />
        <div />
      </StyledBurger>
      <RightNav />
    </Container>
  )
}
