import styled from 'styled-components'

import { breakpoints } from '@/styles/global'

export const Container = styled.div`
  max-width: ${breakpoints.maxWidth};
  margin: 0 auto;
  padding: 40px;

  display: flex;
  flex-direction: column;
  align-items: center;

  > h2 {
    font-weight: 300;
    text-align: center;
    padding: 64px 0;

    span {
      font-weight: 900;
    }
  }

  > div {
    display: flex;
    align-items: baseline;
    column-gap: 30px;
    row-gap: 40px;
  }
`

export const ContentContainer = styled.div`
  display: flex;

  @media (max-width: ${breakpoints.maxWidth}) {
    flex-wrap: wrap;
  }
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;

  h2 {
    color: var(--white);
    font-weight: 200;
    margin-bottom: 1rem;
  }
`
