import { Container, Message } from './styles'

type FooterProps = {
  applyBackground?: boolean
}

export function Footer({ applyBackground = false }: FooterProps) {
  return (
    <Container applyBackground={applyBackground}>
      <Message>Developed with ❤️ by Moésio Marcelino</Message>
    </Container>
  )
}
