import styled from 'styled-components'

export const TitleStyled = styled.h2`
  font-weight: 300;
  text-align: center;
  padding: 40px 0 64px;

  span {
    margin-left: 5px;
    font-weight: 900;
    color: var(--yellow);
  }
`
