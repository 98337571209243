import styled from 'styled-components'
import { breakpoints } from '@/styles/global'

export const Container = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 8px;

  padding: 16px;
  background-color: var(--gray-300);

  h3 {
    text-transform: uppercase;

    @media (max-width: ${breakpoints.maxWidth}) {
      margin-top: 32px;
    }
  }

  p {
    margin-top: 32px;
  }

  @media (max-width: ${breakpoints.maxWidth}) {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-start;
  }
`

export const Image = styled.div<{ image: string }>`
  min-width: 300px;
  height: 200px;
  border-radius: 4px;
  background-image: url(${({ image }) => image && image});
  background-color: var(--white);
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;

  @media (max-width: ${breakpoints.maxWidth}) {
    width: 100%;
    min-width: auto;
  }
`

export const Content = styled.div`
  color: var(--white);
  margin-left: 32px;

  @media (max-width: ${breakpoints.maxWidth}) {
    margin-left: 0;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  margin-top: 32px;

  a {
    text-decoration: none;
    border: 1px solid var(--white);
    box-sizing: border-box;
    border-radius: 8px;
    color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
    cursor: pointer;

    & + a {
      margin-left: 16px;
    }

    transition: 0.4s all;

    &:hover {
      border: 1px solid var(--yellow);
      color: var(--yellow);
    }

    svg {
      margin-right: 8px;
    }

    @media (max-width: ${breakpoints.maxWidth}) {
      flex: 1;
    }
  }

  @media (max-width: ${breakpoints.maxWidth}) {
    display: flex;
    justify-content: space-between;
  }
`
