import styled from 'styled-components'
import { BannerProps } from '.'

export const Container = styled.div<Partial<BannerProps>>`
  width: 100vw;
  height: 100vh;
  background-image: url(${({ image }) => image && image});
  background-color: var(--white);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  row-gap: 20px;

  padding: 64px;
  text-align: center;

  h1 {
    overflow: hidden;
    padding: 10px 20px;
    font-weight: 300;
    transition: 0.4s all;
    border: 0.2px solid var(--black);
    background-color: var(--black);
    color: var(--yellow);

    span {
      font-weight: 500;
    }
  }

  strong {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 4px;
    text-transform: uppercase;
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    background: var(--black);
    color: var(--yellow);
    padding: 10px;
    text-align: center;

    display: flex;
    align-items: center;

    span {
      margin: 0 10px;
      font-weight: 900;
    }
  }
`
