import { Title } from '@/components'
import { Card } from './Card'
import {
  weather,
  githubFinder,
  shoes,
  moveIt,
  money,
  dropbox,
  coffeeDelivery,
  doctorCare,
  igniteShop,
  ghBlog,
  passIn
} from '@/assets'

import { Container, MoreOptions } from './styles'

const projects = [
  {
    image: passIn,
    title: 'Pass In',
    description:
      'Project developed within turbo repo to enable different types of projects as packages and run at once.',
    link: {
      github: 'https://github.com/MoesioMarcelino/pass-in',
      demo: 'https://pass-in.moesiomarcelino.com/'
    }
  },
  {
    image: ghBlog,
    title: 'GitHub Blog',
    description:
      'A blog to show integration with github platform and learn how to handle markdown content types',
    link: {
      github: 'https://github.com/MoesioMarcelino/github-blog',
      demo: 'https://github-blog.moesiomarcelino.com/'
    }
  },
  {
    image: igniteShop,
    title: 'Ignite Shop',
    description:
      'This is a website created to study how to implement carousel within NextJS and integrate it with prisma services',
    link: {
      github: 'https://github.com/MoesioMarcelino/ignite-shop',
      demo: 'https://ignite-shop.moesiomarcelino.com/'
    }
  },
  {
    image: doctorCare,
    title: 'DoctorCare',
    description:
      'This an institucional application to demonstrate all benefits to use doctor care services. To me, its manly target is practice knowledgements about React, UI/UX and improve skills inside frontend javascript universe',
    link: {
      github: 'https://github.com/MoesioMarcelino/doctorCare',
      demo: 'https://doctor-care.moesiomarcelino.com/'
    }
  },
  {
    image: coffeeDelivery,
    title: 'Coffee delivery',
    description:
      'An app to improve your coffee sale, manage a store, stock and check out a shopping list',
    link: {
      github: 'https://github.com/MoesioMarcelino/coffee-delivery',
      demo: 'https://coffee-delivery.moesiomarcelino.com/'
    }
  },
  {
    image: moveIt,
    title: 'Move It',
    description:
      'An application to improve your healthy, doing phisical exercises, or something like that controlled by time and delivering gifts.',
    link: {
      github: 'https://github.com/MoesioMarcelino/move.it',
      demo: 'https://moveit.moesiomarcelino.com'
    }
  },
  {
    image: shoes,
    title: 'Shoes Shop',
    description: 'An application to simulate an online store using React and Redux',
    link: {
      github: 'https://github.com/MoesioMarcelino/Shoes',
      demo: 'https://shoes.moesiomarcelino.com'
    }
  },
  {
    image: weather,
    title: 'Weather App',
    description: 'An application to get weather conditons from any city',
    link: {
      github: 'https://github.com/MoesioMarcelino/Weather',
      demo: 'https://weather.moesiomarcelino.com'
    }
  },
  {
    image: githubFinder,
    title: 'GitHub Finder',
    description:
      'GitHub Finder is an application to find users from GitHub. This application displays user informations, your location in map and repositories that user starred',
    link: {
      github: 'https://github.com/moesiomarcelino/gitHub-Finder',
      demo: 'https://githubfinder.moesiomarcelino.com'
    }
  },
  {
    image: dropbox,
    title: 'Dropbox Clone',
    description: 'A clone from dropbox homepage to learn more about ReactJS and CSS Effects',
    link: {
      github: 'https://github.com/MoesioMarcelino/DropboxClone',
      demo: 'https://dropbox.moesiomarcelino.com'
    }
  },
  {
    image: money,
    title: 'MM Money',
    description:
      "This is an application to learn react's bases. I learn more components, styled-components, hooks, context API and Fake API (miragejs).",
    link: {
      github: 'https://github.com/MoesioMarcelino/MM-Money',
      demo: 'https://money.moesiomarcelino.com'
    }
  }
]

export function Portfolio() {
  return (
    <Container id="portfolio-section">
      <Title additional="Featured" title="Portfolio" />

      <div
        style={{ display: 'grid', gridTemplateColumns: 'repeat(2fr)', gridTemplate: 'repeat(2fr)' }}
      >
        {projects.map((project, index) => (
          <Card key={index} {...project} />
        ))}
      </div>

      <MoreOptions>
        ...and much more{' '}
        <a href="https://github.com/moesiomarcelino" target="_blank" rel="noreferrer">
          here
        </a>
        !
      </MoreOptions>
    </Container>
  )
}
