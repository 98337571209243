import styled from 'styled-components'

type ContainerProps = {
  applyBackground: boolean
}

type LiProps = {
  active: boolean
}

export const Container = styled.ul<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;
  gap: 48px;

  background-color: ${({ applyBackground }) => (applyBackground ? 'var(--gray)' : 'transparent')};
  height: 56px;

  transition: all 0.4s;
`

export const Li = styled.li<LiProps>`
  list-style: none;
  text-transform: uppercase;
  cursor: pointer;
  color: ${({ active }) => active && 'var(--yellow)'};
  font-weight: ${({ active }) => active && 700};
  padding: ${({ active }) => active && '10px'};
  background-color: ${({ active }) => active && 'var(--gray)'};

  &:hover {
    color: var(--yellow);
  }
`
