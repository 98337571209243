import { useMemo } from 'react'

import { Title } from '@/components'
import { Card } from './Card'

import { Container } from './styles'

export function Testimonial() {
  const content = useMemo(
    () => [
      {
        text: 'Moesio is not only a great and precise Software Engineer but also an inspiring person. He consistently demonstrated a solid work ethic at SalesPond along with a commitment to success. He is dedicated, self-motivated, methodical, and with a very positive attitude towards work. I thoroughly appreciated collaborating with him and would definitely recommend him to any organisation looking for a good software engineer.',
        author: 'Debony Schmidt'
      },
      {
        text: 'Moesio é um excelente profissional, engajado com o time e sempre executa da melhor forma possível o que lhe é atribuído. Tive a oportunidade de trabalhar no mesmo projeto que ele e foi muito gratificante. 👏🏼',
        author: 'Janaina Feitosa'
      },
      {
        text: 'Moésio é um profissional muito focado, dedicado, atento aos detalhes e a  qualidade do código e da funcionalidade. Altamente competente, tem proatividade,  em aprender e desde que entrou no projeto agregou muito a equipe. Muito bem comunicativo e de ótimo relacionamento interpessoal.',
        author: 'Rafaela Queiroz'
      },
      {
        text: 'Moésio é uma pessoa bastante comunicativa e sugestiva, alem de um excelente FrontEnd, projeta muito bem telas.',
        author: 'Francisco Wilcley Lacerda de Lima'
      }
    ],
    []
  )
  return (
    <Container id="testimonial-section">
      <Title additional="My" title="Testimonial" />

      {content.map((con, index) => (
        <Card key={index} {...con} />
      ))}
    </Container>
  )
}
