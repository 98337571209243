import React, { useMemo, useCallback, useState, useEffect } from 'react'

import {
  About,
  Banner,
  Contact,
  Footer,
  Portfolio,
  Resume,
  Skills,
  Testimonial
} from '@/components'
import { backgroundBanner } from '@/assets'

import { Container } from './styles'

const App: React.FC = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const banner = useMemo(
    () => ({
      image: backgroundBanner,
      title: `Hello, I'm `,
      strong: 'Moésio Marcelino',
      subtitle: 'This is my resume'
    }),
    []
  )

  const handleCloseMenu = useCallback(() => {
    if (menuIsOpen) {
      setMenuIsOpen(false)
    }
  }, [menuIsOpen])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])

  return (
    <Container onClick={handleCloseMenu}>
      <Banner {...banner} />
      <Portfolio />
      <Resume />
      <About />
      <Testimonial />
      <Skills />
      <Contact />
      <Footer applyBackground />
    </Container>
  )
}

export default App
