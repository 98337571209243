import styled from 'styled-components'

export const Container = styled.div`
  background-color: var(--gray-300);
  padding: 30px;
  transition: 0.3s all ease;
  border: 4px solid transparent;
  border-radius: 7px;
  color: var(--gray-900);
`

export const Date = styled.span`
  margin-bottom: 12px;
  font-size: 12px;
  font-weight: 700;
  color: var(--white);
  display: flex;
  align-items: center;

  svg {
    margin-right: 10px;
  }
`

export const Separator = styled.div`
  height: 15px;
  width: 2px;
  background: var(--white);
  margin: 0 10px;
`

export const Title = styled.div`
  color: var(--yellow);
  font-size: 20px;
`

export const Description = styled.div`
  margin-top: 0;
  margin-bottom: 1rem;
  text-align: justify;
  line-height: 24px;
  margin-top: 1rem;
`

export const Place = styled.div`
  margin-top: 1rem;
`
