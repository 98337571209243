import styled from 'styled-components'
import { breakpoints } from '@/styles/global'

export const Container = styled.div`
  max-width: ${breakpoints.maxWidth};
  margin: 0 auto;
  padding: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > h2 {
    font-weight: 300;
    text-align: center;
    padding: 64px 0;

    span {
      font-weight: 900;
    }
  }
`

export const MoreOptions = styled.div`
  margin-top: 48px;
  color: var(--white);

  text-align: center;

  a {
    color: var(--yellow);
    cursor: pointer;
    text-decoration: none;
    font-weight: 700;
  }
`
