import { createGlobalStyle } from 'styled-components';

export const breakpoints = {
  maxWidth: '1080px'
}

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden;
    scroll-behavior: smooth;
  }

  :root {
    --white: #FFFFFF;
    --yellow: #F1A91D;
    --gray: #222222;
    --black: #000000;
    --dark: #373B40;
    --gray-300: #333333;
    --gray-900: #999999;
  }

  body {
    background-color: var(--gray);
    color: var(--white);
    display: flex;

    #root {
      display: flex;
    }
  }

  h1 {
    font-size: 62px;
  }

  h1, h2 {
    color: var(--white);
  }

  h3 {
    color: var(--yellow);
    font-size: 16px;
  }
`;
