import { useMenu, useWindow } from '@/hooks'
import { Container, Li } from './styles'

export function MenuDesktop() {
  const { menuList, onClickMenuItem } = useMenu()
  const { isScrollDown } = useWindow()

  return (
    <Container applyBackground={isScrollDown}>
      {menuList.map(({ label, id, active }) => (
        <Li key={id} active={active} onClick={() => onClickMenuItem(id)}>
          <span>{label}</span>
        </Li>
      ))}
    </Container>
  )
}
