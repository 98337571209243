import styled, { css } from 'styled-components'
import { breakpoints } from '@/styles/global'

interface Props {
  open: boolean
}

export const Container = styled.div`
  background-color: var(--gray);
  position: fixed;
  width: 100vw;
  padding: 10px 40px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  img {
    display: none;

    @media (max-width: ${breakpoints.maxWidth}) {
      display: block;
    }
  }
`

export const StyledBurger = styled.div<Props>`
  display: none;

  @media (max-width: ${breakpoints.maxWidth}) {
    width: 42px;
    height: 42px;
    overflow-y: hidden;
    z-index: 2;
    align-items: center;
    display: none;
    cursor: pointer;
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;

    ${({ open }) =>
      !open &&
      css`
        height: 40px;
        width: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5px;
        border-radius: 8px;
      `}
  }

  div {
    width: 30px;
    height: 3px;
    background-color: var(--gray-900);
    border-radius: 10px;
    transform-origin: -4px;
    transition: all 0.3s linear;

    &:nth-child(1) {
      transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
    }
    &:nth-child(2) {
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
    }

    & + div {
      ${({ open }) =>
        !open &&
        css`
          margin-top: 6px;
        `}
    }
  }
`
