import styled, { css } from 'styled-components'

type Props = {
  applyBackground: boolean;
}
export const Container = styled.div<Props>`
  margin: 0 auto;
  padding: 40px;

  ${({ applyBackground }) => applyBackground && css`
    background-color: var(--gray-300);
  `}
`

export const Message = styled.div`
  text-align: center;
`
