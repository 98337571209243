import { Container } from './styles'

export type BannerProps = {
  image?: string
  title?: string
  subtitle?: string
  strong?: string
}

export function Banner({ image, title, subtitle, strong }: BannerProps) {
  return (
    <Container image={image} id="home-section">
      <h1>
        {title}
        <span>{strong}</span>
      </h1>
      <strong>
        <span>—</span>
        {subtitle}
        <span>—</span>
      </strong>
    </Container>
  )
}
