import styled from 'styled-components'
import { breakpoints } from '@/styles/global'

export const Container = styled.div`
  max-width: ${breakpoints.maxWidth};
  margin: 0 auto;
  display: flex;

  h2 {
    padding: 64px 0;
  }
`

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;

  padding: 0 32px;
`

export const Span = styled.h3`
  color: var(--yellow);
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 16px;
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;

  & + div {
    margin-top: 40px;
  }

  @media (max-width: 1024px) {
    & + div {
      margin-top: 16px;
    }
  }
`

export const IconsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
`

export const Div = styled.div`
  position: relative;
  overflow: hidden;

  &:hover {
    span {
      display: block;
    }
  }
`

export const Icon = styled.div<{ tooltip: string }>`
  text-decoration: none;
  padding: 12px;
  border: 1px solid var(--white);
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  transition: all 0.4s;

  margin-right: 16px;
  margin-bottom: 16px;

  svg {
    font-size: 24px;
    color: var(--white);
    transition: color 0.4s;
  }

  img {
    height: 24px;
    width: 24px;
    color: var(--yellow);
    transition: color 0.4s;
  }

  position: relative;

  &:hover {
    border: 1px solid var(--yellow);

    svg,
    img {
      color: var(--yellow);
    }

    svg path {
      fill: var(--yellow);
    }
  }

  &:hover::before {
    position: absolute;
    top: -20px;
    background-color: var(--white);
    color: var(--gray);
    content: ${({ tooltip }) => tooltip};
  }
`

export const Tooltip = styled.span`
  position: absolute;
  bottom: -2px;
  left: 50%;
  text-align: center;
  transform: translateX(-70%);
  color: var(--yellow);

  display: none;
  transition: display 0.4s;
`
